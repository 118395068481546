import React from 'react';
import {navigate} from 'gatsby';
import {useLocation} from '@reach/router';
import {graphql} from 'gatsby';
import tw, {styled} from 'twin.macro';

import Layout from '../components/Layout';
import {Container} from '../components/Grid';
import {StyledHeading, Header} from '../components/Page/home/WorkWithUs';
import SEO from '../components/Seo';
import {useI18next} from 'gatsby-plugin-react-i18next';
import PageHero from '../components/shared/hero';
import {Button} from '../components/Button';
import {CardComponent} from '../components/Page/office-cleaning/Included';

const StyledBlock = styled.div`
  padding: 35px;
  background-color: #f8f9f8;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 100px;
  }
  @media ${props => props.theme.screens.lg} {
    padding: 100px;
    margin-bottom: 0px;
  }
`;

const StyledButton = styled(Button)`
  ${tw`w-full md:w-max`}
  border-radius: 10px;
  height: 46px;
  margin-top: 27px;
  text-align: center;

  @media ${props => props.theme.screens.md} {
    height: 68px;
    font-size: 21px;
  }
`;

const DescriptionText = styled.p`
  font-family: Quicksand;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  text-align: center;

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
  }
`;

export const JobButton = ({url, text}) => {
  return <StyledButton onClick={() => navigate(url)}>{text}</StyledButton>;
};
export const DescriptionBlock = ({content, button_text}) => {
  return (
    <Container>
      <StyledBlock>
        <DescriptionText>{content}</DescriptionText>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
          }}
        >
          <BookButton buttonText={button_text} />
        </div>
      </StyledBlock>
    </Container>
  );
};

const StyledText = styled.p`
  a {
    color: #039259;
    text-decoration: underline;
  }
`;

const CareerPage = ({data}) => {
  const {pathname} = useLocation();
  const {language} = useI18next();
  const {background, backgroundMobile, backgroundTablet, careerPage} = data;
  console.log(careerPage);
  return (
    <Layout headerStyle="homeCleaning">
      <SEO
        title={careerPage.seo.title}
        description={careerPage.seo.description}
        lang={language}
      />
      <PageHero
        isContentful={true}
        content={{
          heading: careerPage.heroHeadline,
          text: JSON.parse(careerPage.heroText.raw),
        }}
        imgObj={{
          background,
          backgroundMobile: backgroundMobile,
          backgroundTablet: backgroundTablet,
        }}
      />
      <Container>
        <div style={{marginTop: 60}}>
          <Header>
            <StyledHeading>
              <span>{careerPage.jobPostsHeadline}</span>
            </StyledHeading>
          </Header>
        </div>

        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
          }}
        >
          {careerPage.jobPosts.map(post => (
            <div className="w-full lg:w-1/2 p-4" key={post.headline}>
              <CardComponent heading={post.headline}>
                <StyledText
                  style={{marginBottom: 64}}
                  dangerouslySetInnerHTML={{
                    __html:
                      post.childContentfulJobPostShortDescriptionTextNode
                        .childMarkdownRemark.html,
                  }}
                />
                <JobButton url={`${pathname}${post.niceUrl}`} text={post.buttonLabel}/>
              </CardComponent>
            </div>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default CareerPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {
          in: [
            "common"
            "cleaning_service"
            "seo_cleaning_services_prague"
            "homepage"
          ]
        }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    careerPage: contentfulCareerPage(node_locale: {eq: $language}) {
      seo {
        title
        description
      }
      heroHeadline
      jobPosts {
        niceUrl
        childContentfulJobPostShortDescriptionTextNode {
          childMarkdownRemark {
            html
          }
        }
        headline
        buttonLabel
      }
      heroText {
        raw
      }
      jobPostsHeadline
    }

    background: file(relativePath: {eq: "career-hero.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundTablet: file(relativePath: {eq: "career-hero-tablet.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(relativePath: {eq: "career-hero-mobile.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
  }
`;
